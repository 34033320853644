jQuery(document).ready(function(){
    jQuery(".btn-scroll, .menu-item").click(function(event){
        event.preventDefault();
         var target = jQuery(this).find('a').attr('href');
         var target_offset = jQuery(target).offset();
        var target_top = target_offset.top;
        jQuery('html, body').animate({scrollTop:target_top}, 1500);

 	});

});

 	function goToAnchor(target){
 		var target_offset = jQuery('#'+target).offset();
        var target_top = target_offset.top;
        jQuery('html, body').animate({scrollTop:target_top}, 1500);

 	}

