jQuery(document).ready(function() {
    //http://www.formvalidator.net/
   $.validate({
    lang: 'en',
    onSuccess : function(data) {
      var name = $('#name').val();
      var email = $('#email').val();
      var phone = $('#phone').val();
      var message = $('#message').val();
      $.ajax({  
        type: 'POST',  
        url: '/wordpress/wp-content/themes/khs/send-form.php', 
        data: { name: name, phone: phone, email: email, message: message  },
        success: function(data) {
            jQuery('.msg-success').removeClass('hide');
            console.log(data);
        }
    });
      return false;
    }
  });
}); 