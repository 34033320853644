jQuery(document).ready(function() {
    //http://mreq.github.io/


    jQuery('.js-slick-lightbox--realisation').slickLightbox({
      	caption: 'caption',
		 itemSelector: '> li > a',
      }).on({
      	'show.slickLightbox': function(){ 
			jQuery('.slick-lightbox .slick-next, .slick-lightbox .slick-prev').html('<i class="fa fa-arrow-left"></i>');
		 },

	});
});